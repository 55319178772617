.app-layout {
    .ant-layout-sider-children {
        @apply h-full flex flex-col items-center;
        border-right: 1px solid #eee;
        
        .ant-menu {
            @apply border-0;
        }

        .ant-btn.trigger {
            @apply mt-auto mb-4;
        }
    }

    main {
        @apply m-6;
    }
}