* {
    box-sizing: border-box;
}

body {
    @apply text-16;
}

svg {
    @apply inline;
}
